import React from "react";
import { ReactComponent as FooterLinkDiscoverSectors } from "app/assets/vectors/FooterLinkDiscoverSectors.svg";
import { ReactComponent as FooterLinkDiscoverLocations } from "app/assets/vectors/FooterLinkDiscoverLocations.svg";
import { ReactComponent as FooterLinkDiscoverOrganisations } from "app/assets/vectors/FooterLinkDiscoverOrganisations.svg";
import { ReactComponent as FooterLinkSearchFilterActivities } from "app/assets/vectors/FooterLinkSearchFilterActivities.svg";
import { ReactComponent as PublisherFooterLinkDiscoverLocations } from "app/assets/vectors/PublisherFooterLinkDiscoverLocations.svg";
import { ReactComponent as PublisherFooterLinkDiscoverOrganisations } from "app/assets/vectors/PublisherFooterLinkDiscoverOrganisations.svg";
import { ReactComponent as PublisherFooterLinkAIDAExplorer } from "app/assets/vectors/PublisherFooterLinkAIDAExplorer.svg";
import { Box, Container, useMediaQuery } from "@mui/material";
import { CtaCommonBtn } from "../cta-common-btn";
import PublisherMediumCtaIcon from "app/assets/vectors/jsx/PublisherMediumCtaIcon";
import { customBreakpointsMax } from "app/theme";
import PublisherSmallCtaIcon from "app/assets/vectors/jsx/PublisherSmallCtaIcon";
import { useLocation } from "react-router-dom";

const ResponsiveCTAs: React.FC = () => {
  const mobile = useMediaQuery("(max-width: 743px)");
  const tablet = useMediaQuery("(max-width: 1439px)");
  const location = useLocation();

  const publisher =
    location.pathname.startsWith("/publisher") ||
    location.pathname.startsWith("/data-hub");

  const tabletLinks = [
    {
      label: "Publish to IATI",
      link: "/publisher",
      bgIcon: <PublisherMediumCtaIcon />,
    },
    {
      label: publisher ? "See AIDA Explorer" : "Search for Locations",
      link: publisher ? "/explorer" : "/explorer/locations",
      bgIcon: publisher ? (
        <PublisherFooterLinkAIDAExplorer />
      ) : (
        <FooterLinkDiscoverLocations />
      ),
    },
    {
      label: publisher ? "Search for Locations" : "Search for Sectors",
      link: publisher ? "/explorer/locations" : "/explorer/sectors",
      bgIcon: publisher ? (
        <PublisherFooterLinkDiscoverLocations />
      ) : (
        <FooterLinkDiscoverSectors />
      ),
    },
    {
      label: publisher
        ? "Search for Organisations"
        : "Search & Filter Activities",
      link: publisher ? "/explorer/organisations" : "/explorer/activities",
      bgIcon: publisher ? (
        <PublisherFooterLinkDiscoverOrganisations />
      ) : (
        <FooterLinkSearchFilterActivities />
      ),
    },
  ];

  const mobileLinks = [
    {
      label: "AIDA Publisher",
      link: "/publisher",
      bgIcon: <PublisherSmallCtaIcon />,
    },
    {
      label: publisher ? "AIDA Explorer" : "See Organisations",
      link: publisher ? "/explorer" : "/explorer/organisations",
      bgIcon: publisher ? (
        <PublisherFooterLinkAIDAExplorer />
      ) : (
        <FooterLinkDiscoverOrganisations />
      ),
    },
    {
      label: publisher ? "See Locations" : "See Sectors",
      link: publisher ? "/explorer/locations" : "/explorer/sectors",
      bgIcon: publisher ? (
        <PublisherFooterLinkDiscoverLocations />
      ) : (
        <FooterLinkDiscoverSectors />
      ),
    },
    {
      label: publisher ? "See Organisations" : "See Activities",
      link: publisher ? "/explorer/organisations" : "/explorer/activities",
      bgIcon: publisher ? (
        <PublisherFooterLinkDiscoverOrganisations />
      ) : (
        <FooterLinkSearchFilterActivities />
      ),
    },
  ];
  return (
    <React.Fragment>
      {tablet ? (
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            padding: "0 30px",
            [customBreakpointsMax.mobile]: {
              padding: "0 24px",
              paddingBottom: "20px",
            },
          }}
        >
          <Box
            sx={{
              gap: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              [customBreakpointsMax.mobile]: {
                flexWrap: "wrap",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              },
            }}
          >
            {(mobile ? mobileLinks : tabletLinks).map((link, index) => (
              <CtaCommonBtn
                {...link}
                key={link.label}
                variant="footer"
                bgColor={
                  link.link === "/publisher" || publisher ? "green" : "blue"
                }
              />
            ))}
          </Box>
        </Container>
      ) : null}
    </React.Fragment>
  );
};

export default ResponsiveCTAs;
