import React from "react";
import Grid from "@mui/material/Grid";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";
import { ReactComponent as AIDAExplorerIcon } from "app/assets/vectors/HeaderProducsViewAIDAExplorer.svg";
import { ReactComponent as SectorExplorerIcon } from "app/assets/vectors/HeaderProductViewSectorExplorer.svg";
import { ReactComponent as YourFavouritesIcon } from "app/assets/vectors/HeaderProductViewYourFavourites.svg";
import { ReactComponent as LocationExplorerIcon } from "app/assets/vectors/HeaderProductViewLocationExplorer.svg";
import { ReactComponent as ActivitiesExplorerIcon } from "app/assets/vectors/HeaderProductViewActivitiesExplorer.svg";
import { ReactComponent as OrganisationExplorerIcon } from "app/assets/vectors/HeaderProductViewOrganisationExplorer.svg";

const items = [
  {
    title: "AIDA Explorer",
    icon: AIDAExplorerIcon,
    subtitle: "See IATI data with visualisations & insights.",
    link: "/explorer",
  },
  {
    title: "Location Explorer",
    icon: LocationExplorerIcon,
    subtitle: "See Location-Based Insights.",
    link: "/explorer/locations",
  },
  {
    title: "Organisation Explorer",
    icon: OrganisationExplorerIcon,
    subtitle: "See Organisation-Based Insights.",
    link: "/explorer/organisations",
  },
  {
    title: "Sector Explorer",
    icon: SectorExplorerIcon,
    subtitle: "See Sector-Based Insights.",
    link: "/explorer/sectors",
  },
  {
    title: "Activities Explorer",
    icon: ActivitiesExplorerIcon,
    subtitle: "See and filter activities.",
    link: "/explorer/activities",
  },
  {
    title: "Your Favourites",
    icon: YourFavouritesIcon,
    subtitle: "Save activities, stay in the loop.",
    link: "/explorer/favourites",
  },
];

export const HeaderExplorerView: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12} md={12} lg={4} xl={3} key={item.title}>
          <HeaderViewCommonBlockItem
            title={item.title}
            icon={<item.icon />}
            subtitle={item.subtitle}
            link={item.link}
          />
        </Grid>
      ))}
    </Grid>
  );
};
