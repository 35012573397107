import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { colors, customBreakpointsMax } from "app/theme";

export interface HeaderExpandedViewProps {
  activeButton: string;
  handleButtonClick: (label: string) => void;
}

interface HeaderViewCommonBlockItemProps {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  link?: string;
  hoverTitleColor?: string;
}

export const HeaderViewCommonBlockItem: React.FC<
  HeaderViewCommonBlockItemProps
> = (props: HeaderViewCommonBlockItemProps) => {
  const mobile = useMediaQuery("(max-width: 743px)");
  const tablet = useMediaQuery("(max-width: 1439px)");
  return (
    <Box
      sx={{
        gap: "10px",
        padding: "5px",
        display: "flex",
        cursor: "pointer",
        borderRadius: "5px",
        flexDirection: "row",
        textDecoration: "none",
        alignItems: "flex-start",
        border: "1px solid transparent",
        ":hover": {
          borderColor: colors.secondary.iconGray,
          background: colors.secondary.disableButton,
          h5: {
            color: props.hoverTitleColor ?? colors.primary.blue,
          },
        },
        [customBreakpointsMax.tablet]: {
          border: "none",
          padding: 0,
          "&:hover": {
            border: "none",
            background: "none",
          },
        },
      }}
      to={props.link}
      component={props.link ? Link : "div"}
      data-cy="header-view-common-block-item"
    >
      {!tablet && props.icon}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          [customBreakpointsMax.tablet]: {
            gap: 0,
          },
        }}
      >
        <Typography
          variant="h5"
          fontSize="14px"
          margin="0"
          sx={{
            [customBreakpointsMax.tablet]: {
              color: colors.primary.blue,
              ":hover": {
                textDecoration: "underline",
              },
            },
          }}
        >
          {props.title}
        </Typography>
        {!mobile && (
          <Typography
            fontSize="12px"
            variant="h6"
            fontWeight="400"
            lineHeight={"normal"}
          >
            {props.subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
