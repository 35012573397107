import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const user: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/user/me`),
};

export const updateUser: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/user/me`),
};

export const IntercomUser: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/user/intercom-user`),
};

export const StripeSubscriptionPlan: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/payments/subscription/{id}`),
};

export const StripePaymentMethod: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/payments/customer/payment-method/{id}`
  ),
};

export const StripeBillingInfo: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/payments/customer/{id}/billing`),
};

export const StripeInvoices: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/payments/customer/{id}/invoices`),
};
