import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { colors, customBreakpointsMax } from "app/theme";
import { HEADER_BUTTON_ITEMS } from "app/components/header/sub-components/buttons/data";
import { HeaderExpandedViewProps } from "app/components/header/sub-components/expanded-view/data";
import { HeaderSignInView } from "app/components/header/sub-components/expanded-view/views/signin";
import { HeaderPublisherView } from "app/components/header/sub-components/expanded-view/views/publisher";
import { HeaderExplorerView } from "app/components/header/sub-components/expanded-view/views/explorer";
import { HeaderResourcesView } from "app/components/header/sub-components/expanded-view/views/resources";

export const HeaderExpandedView: React.FC<HeaderExpandedViewProps> = (
  props: HeaderExpandedViewProps
) => {
  const view = React.useMemo(() => {
    switch (props.activeButton) {
      case HEADER_BUTTON_ITEMS[0].label:
        return <HeaderExplorerView />;
      case HEADER_BUTTON_ITEMS[1].label:
        return <HeaderPublisherView />;
      case HEADER_BUTTON_ITEMS[2].label:
        return <HeaderResourcesView />;
      case HEADER_BUTTON_ITEMS[4].label:
        return <HeaderSignInView />;
      default:
        return null;
    }
  }, [props.activeButton]);

  return (
    <Box
      sx={{
        left: 0,
        width: "100vw",
        position: "absolute",
        background: colors.secondary.lightGrey,
        borderBottom: `1px solid ${colors.secondary.iconGray}`,
      }}
    >
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          [customBreakpointsMax.tablet]: {
            padding: "0 40px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "20px 0",
            flexDirection: "column",
            background: colors.secondary.lightGrey,
          }}
        >
          {view}
        </Box>
      </Container>
    </Box>
  );
};
