/* eslint-disable no-param-reassign */
import uniq from "lodash/uniq";
import { Action, action } from "easy-peasy";
import { TransactionType } from "app/pages/explorer/components/transaction-type/data";

export interface AppliedFiltersStateModel {
  startDate: string;
  endDate: string;
  countries: string[];
  sectors: string[];
  publishers: string[];
  activityStatus: string[];
  budgetMin: number;
  budgetMax: number;
  transactionType: TransactionType | null;
}

export interface AppliedFiltersModel extends AppliedFiltersStateModel {
  setStartDate: Action<AppliedFiltersModel, string>;
  setEndDate: Action<AppliedFiltersModel, string>;
  setCountries: Action<AppliedFiltersModel, string[]>;
  setSectors: Action<AppliedFiltersModel, string[]>;
  setPublishers: Action<AppliedFiltersModel, string[]>;
  setActivityStatus: Action<AppliedFiltersModel, string[]>;
  setBudgetMin: Action<AppliedFiltersModel, number>;
  setBudgetMax: Action<AppliedFiltersModel, number>;
  setTransactionType: Action<AppliedFiltersModel, TransactionType>;
  setAllFilters: Action<AppliedFiltersModel, AppliedFiltersStateModel>;
  resetAllFilters: Action<AppliedFiltersModel>;
}

export const defaultAppliedFilters = {
  startDate: "",
  endDate: "",
  countries: [] as string[],
  sectors: [] as string[],
  publishers: [] as string[],
  activityStatus: [] as string[],
  budgetMin: 0,
  budgetMax: 0,
  transactionType: null,
};

export const appliedFilters: AppliedFiltersModel = {
  ...defaultAppliedFilters,
  setStartDate: action((state, payload: string) => {
    state.startDate = payload;
  }),
  setEndDate: action((state, payload: string) => {
    state.endDate = payload;
  }),
  setCountries: action((state, payload: string[]) => {
    state.countries = uniq(payload);
  }),
  setSectors: action((state, payload: string[]) => {
    state.sectors = uniq(payload);
  }),
  setPublishers: action((state, payload: string[]) => {
    state.publishers = uniq(payload);
  }),
  setActivityStatus: action((state, payload: string[]) => {
    state.activityStatus = uniq(payload);
  }),
  setBudgetMin: action((state, payload: number) => {
    state.budgetMin = payload;
  }),
  setBudgetMax: action((state, payload: number) => {
    state.budgetMax = payload;
  }),
  setTransactionType: action((state, payload: TransactionType) => {
    state.transactionType = payload;
  }),
  resetAllFilters: action((state) => {
    state.startDate = "";
    state.endDate = "";
    state.countries = [];
    state.sectors = [];
    state.publishers = [];
    state.activityStatus = [];
    state.budgetMin = 0;
    state.budgetMax = 0;
    state.transactionType = null;
  }),
  setAllFilters: action((state, payload: AppliedFiltersStateModel) => {
    state.startDate = payload.startDate;
    state.endDate = payload.endDate;
    state.countries = uniq(payload.countries);
    state.sectors = uniq(payload.sectors);
    state.publishers = uniq(payload.publishers);
    state.activityStatus = uniq(payload.activityStatus);
    state.transactionType = payload.transactionType;
  }),
};
