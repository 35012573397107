import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useStoreActions } from "app/state/store/hooks";

export const useInitialLoad = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchLocationFilterOptions = useStoreActions(
    (actions) => actions.ExplorerLocationFilterOptions.fetch
  );
  const fetchPublisherFilterOptions = useStoreActions(
    (actions) => actions.ExplorerPublisherFilterOptions.fetch
  );
  const fetchSectorsFilterOptions = useStoreActions(
    (actions) => actions.ExplorerSectorsFilterOptions.fetch
  );
  const fetchActivityStatusFilterOptions = useStoreActions(
    (actions) => actions.ExplorerActivityStatusFilterOptions.fetch
  );
  const fetchYearFilterOptions = useStoreActions(
    (actions) => actions.ExplorerYearFilterOptions.fetch
  );
  const fetchUserAction = useStoreActions(
    (actions) => actions.user.authGetFetch
  );
  const fetchStripeSubscriptionPlan = useStoreActions(
    (actions) => actions.StripeSubscriptionPlan.authGetFetch
  );
  const fetchStripePaymentMethod = useStoreActions(
    (actions) => actions.StripePaymentMethod.authGetFetch
  );
  const fetchStripeBillingInfo = useStoreActions(
    (actions) => actions.StripeBillingInfo.authGetFetch
  );
  const fetchStripeInvoices = useStoreActions(
    (actions) => actions.StripeInvoices.authGetFetch
  );

  React.useEffect(() => {
    fetchLocationFilterOptions({});
    fetchPublisherFilterOptions({});
    fetchSectorsFilterOptions({});
    fetchActivityStatusFilterOptions({});
    fetchYearFilterOptions({});
  }, []);

  React.useEffect(() => {
    if (isAuthenticated && user) {
      getAccessTokenSilently().then((token: string) => {
        fetchUserAction({ values: { token } });
        fetchStripeSubscriptionPlan({
          values: { token },
          dontEncryptAuthCall: true,
          routeParams: { id: user?.sub as string },
        });
        fetchStripePaymentMethod({
          values: { token },
          dontEncryptAuthCall: true,
          routeParams: { id: user?.sub as string },
        });
        fetchStripeBillingInfo({
          values: { token },
          dontEncryptAuthCall: true,
          routeParams: { id: user?.sub as string },
        });
        fetchStripeInvoices({
          values: { token },
          dontEncryptAuthCall: true,
          routeParams: { id: user?.sub as string },
        });
      });
    }
  }, [isAuthenticated]);

  return null;
};
