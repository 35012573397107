import { colors } from "app/theme";

export interface HeaderButtonProps {
  label: string;
  link?: string;
  color?: string;
  signIn?: boolean;
  expandable?: boolean;
}

export interface HeaderButtonsProps {
  activeButton: string;
  buttons: HeaderButtonProps[];
  handleButtonClick: (label: string) => void;
}

export const HEADER_BUTTON_ITEMS: HeaderButtonProps[] = [
  {
    label: "AIDA Explorer",
    color: colors.shades.blue[400],
    expandable: true,
  },
  {
    label: "AIDA Publisher",
    color: colors.shades.green[400],
    expandable: true,
  },
  {
    label: "Resources",
    expandable: true,
  },
  {
    label: "Pricing",
    link: "/pricing",
  },
  {
    label: "Sign In",
    signIn: true,
    expandable: true,
  },
];
