import React from "react";
import { colors, customBreakpointsMax } from "app/theme";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinkedIn from "@mui/icons-material/LinkedIn";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { CtaCommonBtn } from "app/components/cta-common-btn";
import { ReactComponent as HeaderToolbarLogo } from "app/assets/vectors/HeaderToolbarLogo.svg";
import { ReactComponent as FooterLinkAIDAExplorer } from "app/assets/vectors/FooterLinkAIDAExplorer.svg";
import { ReactComponent as FooterLinkDiscoverSectors } from "app/assets/vectors/FooterLinkDiscoverSectors.svg";
import { ReactComponent as FooterLinkDiscoverLocations } from "app/assets/vectors/FooterLinkDiscoverLocations.svg";
import { ReactComponent as FooterLinkDiscoverOrganisations } from "app/assets/vectors/FooterLinkDiscoverOrganisations.svg";
import { ReactComponent as FooterLinkSearchFilterActivities } from "app/assets/vectors/FooterLinkSearchFilterActivities.svg";
import { ReactComponent as FooterLinkAIDAExplorerHover } from "app/assets/vectors/FooterLinkAIDAExplorerHover.svg";
import { ReactComponent as FooterLinkDiscoverSectorsHover } from "app/assets/vectors/FooterLinkDiscoverSectorsHover.svg";
import { ReactComponent as FooterLinkDiscoverLocationsHover } from "app/assets/vectors/FooterLinkDiscoverLocationsHover.svg";
import { ReactComponent as FooterLinkDiscoverOrganisationsHover } from "app/assets/vectors/FooterLinkDiscoverOrganisationsHover.svg";
import { ReactComponent as FooterLinkSearchFilterActivitiesHover } from "app/assets/vectors/FooterLinkSearchFilterActivitiesHover.svg";
import { Divider, useMediaQuery } from "@mui/material";
import { useEmailSignup } from "app/hooks/useEmailSignup";

const FooterEl = styled.footer`
  width: 100vw;
  background: #f7f7f7;
  border-top: 1px solid rgba(161, 174, 189, 0.2);
`;

const links = [
  {
    label: "AIDA Explorer",
    link: "/explorer",
    bgIcon: <FooterLinkAIDAExplorer />,
    bgIconHover: <FooterLinkAIDAExplorerHover />,
  },
  {
    label: "Discover Locations",
    link: "/explorer/locations",
    bgIcon: <FooterLinkDiscoverLocations />,
    bgIconHover: <FooterLinkDiscoverLocationsHover />,
  },
  {
    label: "Discover Organisations",
    link: "/explorer/organisations",
    bgIcon: <FooterLinkDiscoverOrganisations />,
    bgIconHover: <FooterLinkDiscoverOrganisationsHover />,
  },
  {
    label: "Discover Sectors",
    link: "/explorer/sectors",
    bgIcon: <FooterLinkDiscoverSectors />,
    bgIconHover: <FooterLinkDiscoverSectorsHover />,
  },
  {
    label: "Search & Filter Activities",
    link: "/explorer/activities",
    bgIcon: <FooterLinkSearchFilterActivities />,
    bgIconHover: <FooterLinkSearchFilterActivitiesHover />,
  },
  {
    label: "Join the monthly AIDA Webinar",
    tooltip: "Coming soon",
  },
];

export const Footer: React.FC = () => {
  const location = useLocation();

  const desktop = useMediaQuery("(min-width: 1440px)");

  const mobile = useMediaQuery("(max-width: 743px)");
  const tablet = useMediaQuery("(max-width: 1439px)");

  const isPublisher = React.useMemo(() => {
    return location.pathname.indexOf("/publisher") > -1;
  }, [location.pathname]);

  const { email, handleSubmit, message, setEmail, setMessage } =
    useEmailSignup();

  return (
    <FooterEl data-cy="footer">
      <Container
        maxWidth="lg"
        sx={{
          height: { lg: "245px" },
          padding: {
            xs: "20px 24px",
            md: "20px 30px",
            lg: "20px 0 !important",
          },
        }}
      >
        {desktop && (
          <Box
            sx={{
              width: "100%",
              height: "64px",
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
              justifyContent: "space-between",
            }}
          >
            {links.map((link, index) => (
              <CtaCommonBtn
                {...link}
                key={link.label}
                variant="footer"
                bgColor={
                  isPublisher && index === links.length - 1 ? "green" : "blue"
                }
              />
            ))}
          </Box>
        )}
        <Box
          sx={{
            gap: { xs: "18px", md: "20px" },
            display: "flex",
            flexWrap: "wrap",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              gap: { xs: "18px", md: "20px" },
              display: "flex",
              flexWrap: "wrap",
              flexDirection: {
                xs: "column-reverse",
                md: "column",
                lg: "row",
              },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                gap: "10px",
                display: "flex",
                maxWidth: { lg: "300px" },
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <HeaderToolbarLogo />
              <Typography fontSize="12px" color={colors.text.title}>
                A comprehensive solution that effortlessly links with the IATI
                Registry, verifies your data, and transforms your IATI
                information into compelling visualizations.
              </Typography>
              <IconButton
                sx={{
                  padding: 0,
                  [customBreakpointsMax.tablet]: {
                    display: "none",
                  },
                }}
              >
                <LinkedIn htmlColor={colors.text.title} />
              </IconButton>
            </Box>
            <Divider
              sx={{
                display: {
                  lg: "none",
                },
              }}
            />
            <Box
              sx={{
                gap: {
                  xs: "10px",
                  lg: "15px",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h6"
                fontSize={{
                  xs: "10px",
                  md: "12px",
                }}
                fontWeight={400}
              >
                Subscribe to our newsletter
              </Typography>
              <Box
                sx={{
                  gap: { xs: "15px", md: "30px", lg: "15px" },
                  display: "flex",
                  flexDirection: { xs: "column", md: "row", lg: "column" },
                  alignItems: "flex-start",
                  width: "100%",
                  "> input": {
                    width: { xs: "100%", md: "268px" },
                    flex: { md: "1", lg: "unset" },
                    height: "32px",
                    fontSize: "12px",
                    padding: "0 20px",
                    borderRadius: "5px",
                    color: colors.text.title,
                    background:
                      colors.shades[isPublisher ? "green" : "blue"][100],
                    border: `1px solid ${colors.text.title}`,
                    "::placeholder": {
                      color: colors.text.title,
                    },
                    ":focus": {
                      outline: "none",
                    },
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  data-cy="newsletter-input"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (message) {
                      setMessage("");
                    }
                  }}
                />
                <Button
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    textTransform: "none",
                    color: colors.primary.white,
                    background: isPublisher
                      ? colors.primary.green
                      : colors.primary.blue,
                    width: {
                      md: "254px",
                      lg: "max-content",
                    },
                    justifyContent: "space-between",
                  }}
                  variant="contained"
                  endIcon={<ArrowForward />}
                  onClick={handleSubmit}
                  data-cy="newsletter-submit-btn"
                >
                  Subscribe
                </Button>
                <Typography
                  data-cy="newsletter-submit-message"
                  variant="h6"
                  fontSize="12px"
                  marginTop={"-10px"}
                >
                  {message}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              display: {
                lg: "none",
              },
            }}
          />{" "}
          <Box
            sx={{
              display: "flex",
              gap: { xs: "18px", md: "20px", lg: "80px" },
              [customBreakpointsMax.tablet]: {
                flexDirection: "column",
              },
              [customBreakpointsMax.mobile]: {
                flexDirection: "row",
                justifyContent: "space-between",
                "> div": {
                  width: "100%",
                },
                height: "max-content",
              },
            }}
          >
            <Box>
              <Typography
                variant="h6"
                fontSize={"12px"}
                display={{ lg: "none" }}
                marginBottom={{ xs: "18px", md: "20px" }}
              >
                Resources
              </Typography>
              <Box
                sx={{
                  gap: {
                    xs: "18px",
                    md: "10px",
                  },
                  display: "flex",
                  flexDirection: "column",
                  "> a": {
                    fontSize: "12px",
                    textDecoration: "none",
                    color: colors.text.title,
                  },
                  [customBreakpointsMax.tablet]: {
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    "> a": {
                      width: "100%",
                    },
                  },
                  [customBreakpointsMax.mobile]: {
                    flexDirection: "column",
                  },
                }}
              >
                {desktop && <Link to="/">Homepage</Link>}
                {tablet && <Link to="/user-guide">AIDA User Guide</Link>}
                <Link to="/data-services">Data Services</Link>
                {!mobile && <Link to="/plans-pricing">Plans & Pricing</Link>}
              </Box>
            </Box>
            <Divider
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                  lg: "none",
                },
              }}
            />{" "}
            <Box>
              <Typography
                variant="h6"
                fontSize={"12px"}
                display={{ lg: "none" }}
                marginBottom={{ xs: "18px", md: "20px" }}
              >
                Product
              </Typography>
              <Box
                sx={{
                  gap: {
                    xs: "18px",
                    md: "10px",
                  },
                  display: "flex",
                  flexDirection: "column",
                  "> a": {
                    fontSize: "12px",
                    textDecoration: "none",
                    color: colors.text.title,
                  },
                  [customBreakpointsMax.tablet]: {
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    "> a": {
                      width: "100%",
                    },
                  },
                  [customBreakpointsMax.mobile]: {
                    flexDirection: "column",
                  },
                }}
              >
                {desktop && (
                  <>
                    <Link to="/user-guide">User Guide</Link>
                    <Link to="/account-profile">My Account</Link>
                  </>
                )}
                {tablet && (
                  <>
                    <Link to="/publisher">AIDA IATI Publisher</Link>
                    <Link to="/explorer">AIDA Explorer</Link>
                    <Link to="/">Homepage</Link>
                    <Link to="/account-profile">My Account</Link>
                  </>
                )}
              </Box>{" "}
            </Box>
          </Box>
          <Divider
            sx={{
              display: {
                lg: "none",
              },
            }}
          />
          <Box
            sx={{
              gap: {
                xs: "18px",
                md: "10px",
              },
              display: "flex",
              flexDirection: { xs: "column", md: "row", lg: "column" },
              "> a": {
                fontSize: "12px",
                textDecoration: "none",
                color: colors.text.title,
                "&:last-of-type": {
                  display: { xs: "inline", lg: "none" },
                },
              },
              justifyContent: { md: "space-between", lg: "flex-start" },
              alignItems: { md: "center", lg: "flex-start" },
            }}
          >
            <Typography fontSize="12px" color={colors.text.title}>
              The Open Data Company B.V.
              <br />
              Papaverweg 34,
              {mobile ? ", " : <br />}
              1032 KJ Amsterdam,
              {mobile ? ", " : <br />}
              The Netherlands
            </Typography>
            <a href="tel:+31205039091">+31 20 503 9091</a>
            <a href="mailto:info@aida.tools">info@aida.tools</a>

            <a href="https://www.linkedin.com/company/zimmerman-team">
              LinkedIn{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
              >
                <path
                  d="M0.646447 9.3066C0.451184 9.50187 0.451184 9.81845 0.646447 10.0137C0.841709 10.209 1.15829 10.209 1.35355 10.0137L0.646447 9.3066ZM9.98528 1.17488C9.98528 0.898733 9.76142 0.674875 9.48528 0.674875L4.98528 0.674875C4.70914 0.674875 4.48528 0.898733 4.48528 1.17488C4.48528 1.45102 4.70914 1.67487 4.98528 1.67488L8.98528 1.67487L8.98528 5.67487C8.98528 5.95102 9.20914 6.17487 9.48528 6.17487C9.76142 6.17488 9.98528 5.95102 9.98528 5.67488L9.98528 1.17488ZM1.35355 10.0137L9.83883 1.52843L9.13173 0.821322L0.646447 9.3066L1.35355 10.0137Z"
                  fill="black"
                />
              </svg>
            </a>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          gap: "20px",
          rowGap: {
            xs: "10px",
            md: "20px",
          },
          width: "100%",
          height: { md: "32px" },
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: { xs: "space-between", md: "center" },
          color: colors.secondary.grayText,
          background: colors.secondary.disableText,
          padding: {
            xs: "8px 20px",
            md: "0",
          },
          "> a": {
            fontSize: { xs: "10px", md: "12px" },
            textDecoration: "none",
            color: colors.secondary.grayText,
          },
          "> p": {
            fontSize: { xs: "10px", md: "12px" },
            color: colors.secondary.grayText,
          },
        }}
      >
        <Typography
          fontSize="18px !important"
          color={colors.secondary.grayText}
        >
          ©
        </Typography>
        <Typography fontSize="12px" color={colors.secondary.grayText}>
          {new Date().getFullYear()} Zimmerman B.V. All Rights Reserved
        </Typography>
        <Typography fontSize="12px" color={colors.secondary.grayText}>
          Privacy Policy
        </Typography>
        <Typography fontSize="12px" color={colors.secondary.grayText}>
          ·
        </Typography>
        <Link to="/privacy">Privacy</Link>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
        <Link to="/disclaimer">Disclaimer</Link>
      </Box>
    </FooterEl>
  );
};
