import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import {
  CtaCommonBtnProps,
  CtaCommonBtnVariantStyles,
} from "app/components/cta-common-btn/data";

export const CtaCommonBtn: React.FC<CtaCommonBtnProps> = (
  props: CtaCommonBtnProps
) => {
  const location = useLocation();

  const variantStyles = React.useMemo(() => {
    return CtaCommonBtnVariantStyles[props.variant];
  }, [props.variant]);

  const isActive = React.useMemo(() => {
    if (props.link) {
      return location.pathname === props.link;
    }
    return false;
  }, [location.pathname]);

  return (
    <Tooltip title={props.tooltip} placement="top" arrow>
      <ButtonBase
        sx={{
          ...variantStyles,
          gap: { xs: "5px", lg: "15px" },
          display: "flex",
          position: "relative",
          flexDirection: "column",
          alignItems: "flex-start",
          cursor: isActive ? "default" : "pointer",
          background: !isActive
            ? colors.primary[props.bgColor ?? "blue"]
            : colors.shades[props.bgColor ?? "blue"][400],
          "> svg": isActive
            ? {
                background: colors.shades[props.bgColor ?? "blue"][500],
              }
            : {},
          '[data-name="icon"]': {
            display: !isActive ? "block" : "none",
          },
          '[data-name="hover-icon"]': {
            display: !isActive ? "none" : "block",
          },
          ":hover": {
            background: colors.shades[props.bgColor ?? "blue"][400],
            "> svg": {
              background: colors.shades[props.bgColor ?? "blue"][500],
            },
            '[data-name="icon"]': {
              display: "none",
            },
            '[data-name="hover-icon"]': {
              display: "block",
            },
          },
        }}
        onClick={props.onClick}
        component={props.link ? Link : "button"}
        to={props.link}
        data-cy="cta-common-btn"
      >
        <Typography
          zIndex={1}
          fontWeight="700"
          textAlign="start"
          color={colors.primary.white}
          fontSize={variantStyles.fontSize}
        >
          {props.label}
        </Typography>
        {props.text && (
          <Typography
            zIndex={1}
            fontSize={{ md: "10px", lg: "12px" }}
            fontWeight="700"
            textAlign="start"
            color={colors.primary.white}
          >
            {props.text}
          </Typography>
        )}
        {props.bgIcon && (
          <Box
            data-name="icon"
            sx={{
              ...variantStyles.icon,
              zIndex: 0,
              position: "absolute",
              color: colors.primary.white,
            }}
          >
            {props.bgIcon}
          </Box>
        )}
        {props.bgIconHover && (
          <Box
            data-name="hover-icon"
            sx={{
              ...variantStyles.icon,
              zIndex: 0,
              position: "absolute",
              color: colors.primary.white,
            }}
          >
            {props.bgIconHover}
          </Box>
        )}
        <ArrowOutward
          htmlColor={colors.primary.white}
          fontSize="small"
          sx={{
            ...variantStyles.arrowIconButton,
            zIndex: 1,
            padding: 0,
            borderRadius: "50%",
            position: "absolute",
            background: colors.shades[props.bgColor ?? "blue"][200],
            ":hover": {
              background: colors.shades[props.bgColor ?? "blue"][500],
            },
          }}
        />
      </ButtonBase>
    </Tooltip>
  );
};
