import React, { Suspense } from "react";
import Box from "@mui/material/Box";
import { Page } from "app/components/page";
import { RouteObject } from "react-router-dom";
import { Redirect } from "app/components/redirect";
import { AuthCallback } from "app/pages/auth-callback";
import { AuthenticatedRoute } from "app/router/authenticated-route";
import { PageLoader } from "app/components/page-loader";

const NotFound: React.FC = () => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 91px - 256px - 150px)",
    }}
  >
    Page not found
  </Box>
);

const REDIRECT_ROUTES: RouteObject[] = [
  {
    path: "/app/search-results",
    element: <Redirect to="/explorer" />,
  },
  {
    path: "/app",
    element: <Redirect to="/explorer" />,
  },
  {
    path: "/app/country/:id",
    element: <Redirect to="/explorer/locations" />,
  },
  {
    path: "/app/donor/:id",
    element: <Redirect to="/explorer/organisations" />,
  },
  {
    path: "/app/publisher/:id",
    element: <Redirect to="/explorer/organisations" />,
  },
  {
    path: "/app/organisation/:id",
    element: <Redirect to="/explorer/organisations" />,
  },
  {
    path: "/app/activity/:id",
    element: <Redirect to="/explorer/activities" />,
  },
  {
    path: "/app/data-manager",
    element: <Redirect to="/publisher" />,
  },
  {
    path: "/app/data-manager/bulk-management",
    element: <Redirect to="/publisher" />,
  },
  {
    path: "/app/data-manager/profile-settings",
    element: <Redirect to="/publisher" />,
  },
  {
    path: "/app/validation-report/:orgRef/:uuid/:filename",
    element: <Redirect to="/data-hub" />,
  },
  {
    path: "/app/xml-file/:orgRef/:uuid/:filename",
    element: <Redirect to="/data-hub" />,
  },
  {
    path: "/app/about",
    element: <Redirect to="/about" />,
  },
  {
    path: "/app/faq",
    element: <Redirect to="/faq" />,
  },
  {
    path: "/app/contact",
    element: <Redirect to="/user-guide#contact-us" />,
  },
  {
    path: "/app/bookmarks",
    element: <Redirect to="/explore/favourites" />,
  },
  {
    path: "/app/user-settings",
    element: <Redirect to="/account-profile" />,
  },
  {
    path: "/app/user-settings/:id",
    element: <Redirect to="/account-profile" />,
  },
  {
    path: "/knowledgeHub",
    element: <Redirect to="/user-guide" />,
  },
  {
    path: "/support",
    element: <Redirect to="/user-guide" />,
  },
  {
    path: "/services",
    element: <Redirect to="/data-services" />,
  },
  {
    path: "/plans",
    element: <Redirect to="/pricing" />,
  },
  {
    path: "/onboarding/:page?",
    element: <Redirect to="/login" />,
  },
  {
    path: "/",
    element: <Redirect to="/data-services" />,
  },
];

export const ROUTES: RouteObject[] = [
  {
    path: "/",
    element: (
      <Suspense fallback={<PageLoader />}>
        <Page />
      </Suspense>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        lazy: async () => {
          const Home = await import("app/pages/home");
          return { Component: Home.default };
        },
      },
      {
        path: "/explorer",
        lazy: async () => {
          const Explorer = await import("app/pages/explorer");
          return { Component: Explorer.default };
        },
      },
      {
        path: "/explorer/locations",
        lazy: async () => {
          const ExplorerLocations = await import(
            "app/pages/explorer/pages/locations"
          );
          return { Component: ExplorerLocations.default };
        },
      },
      {
        path: "/explorer/locations/:id",
        lazy: async () => {
          const ExplorerLocation = await import(
            "app/pages/explorer/pages/locations/pages/location"
          );
          return { Component: ExplorerLocation.default };
        },
      },
      {
        path: "/explorer/organisations",
        lazy: async () => {
          const ExplorerOrganisations = await import(
            "app/pages/explorer/pages/organisations"
          );
          return { Component: ExplorerOrganisations.default };
        },
      },
      {
        path: "/explorer/organisations/:id",
        lazy: async () => {
          const ExplorerOrganisation = await import(
            "app/pages/explorer/pages/organisations/pages/organisation"
          );
          return { Component: ExplorerOrganisation.default };
        },
      },
      {
        path: "/explorer/sectors",
        lazy: async () => {
          const ExplorerSectors = await import(
            "app/pages/explorer/pages/sectors"
          );
          return { Component: ExplorerSectors.default };
        },
      },
      {
        path: "/explorer/sectors/:id",
        lazy: async () => {
          const ExplorerSector = await import(
            "app/pages/explorer/pages/sectors/pages/sector"
          );
          return { Component: ExplorerSector.default };
        },
      },
      {
        path: "/explorer/activities",
        lazy: async () => {
          const ExplorerActivities = await import(
            "app/pages/explorer/pages/activities"
          );
          return { Component: ExplorerActivities.default };
        },
      },
      {
        path: "/explorer/activities/:id",
        lazy: async () => {
          const ExplorerActivity = await import(
            "app/pages/explorer/pages/activities/pages/activity"
          );
          return { Component: ExplorerActivity.default };
        },
      },
      {
        path: "/data-services",
        lazy: async () => {
          const ExplorerDataServices = await import("app/pages/data-services");
          return { Component: ExplorerDataServices.default };
        },
      },
      {
        path: "/data-hub",
        lazy: async () => {
          const DataHub = await import("app/pages/data-hub");
          return { Component: DataHub.default };
        },
      },
      {
        path: "/user-guide",
        lazy: async () => {
          const UserGuide = await import("app/pages/user-guide");
          return { Component: UserGuide.default };
        },
      },
      // Auth
      {
        path: "/login",
        lazy: async () => {
          const Login = await import("app/pages/login");
          return { Component: Login.default };
        },
      },
      {
        path: "/pricing",
        lazy: async () => {
          const Pricing = await import("app/pages/pricing");
          return { Component: Pricing.default };
        },
      },
      {
        path: "/publisher",
        lazy: async () => {
          const Publisher = await import("app/pages/publisher");
          return { Component: Publisher.default };
        },
      },
      {
        path: "/",
        element: <AuthenticatedRoute />,
        children: [
          {
            path: "/account-profile",
            lazy: async () => {
              const AccountProfile = await import("app/pages/account-profile");
              return { Component: AccountProfile.default };
            },
          },
          {
            path: "/explorer/favourites",
            lazy: async () => {
              const ExplorerFavourites = await import(
                "app/pages/explorer/pages/favourites"
              );
              return { Component: ExplorerFavourites.default };
            },
          },
        ],
      },
      {
        path: "/debug",
        lazy: async () => {
          const DebugPage = await import("app/pages/debug");
          return { Component: DebugPage.default };
        },
      },
      { path: "/callback", element: <AuthCallback /> },
      ...REDIRECT_ROUTES,
      // 404
      { path: "*", element: <NotFound /> },
    ],
  },
];
