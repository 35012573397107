/* eslint-disable no-param-reassign */
import get from "lodash/get";
import cryptoJs from "crypto-js";
import forEach from "lodash/forEach";
import { action, thunk } from "easy-peasy";
import axios, { AxiosResponse } from "axios";
import {
  ApiModel,
  Errors,
  RequestValues,
  ResponseData,
} from "app/state/api/interfaces";

export const APPLICATION_JSON = "application/json";

export const APIModel = <QueryModel, ResponseModel>(
  url: string
): ApiModel<QueryModel, ResponseModel> => ({
  loading: false,
  success: false,
  data: {},
  errorData: null,
  onError: action((state, payload: Errors) => {
    state.loading = false;
    state.errorData = payload;
  }),
  onSuccess: action((state, payload: ResponseData<ResponseModel>) => {
    const { addOnData, addOnDataKey, ...actualPayload } = payload;
    state.loading = false;
    state.success = true;
    if (addOnData) {
      state.data = {
        ...state.data,
        data: addOnDataKey
          ? {
              ...get(state, "data.data", {}),
              [addOnDataKey]: {
                ...get(state, `data.data.${addOnDataKey}`, {}),
                items: [
                  ...get(state, `data.data.${addOnDataKey}.items`, []),
                  ...get(actualPayload, `data.${addOnDataKey}.items`, []),
                ],
              },
            }
          : [...get(state, "data.data", []), ...actualPayload.data],
        // @ts-ignore
        count: actualPayload.count,
      };
    } else {
      state.data = actualPayload;
    }
  }),
  setSuccess: action((state) => {
    state.loading = false;
    state.success = true;
  }),
  onRequest: action((state) => {
    state.loading = true;
    state.success = false;
  }),
  fetch: thunk(async (actions, query: RequestValues<QueryModel>) => {
    actions.onRequest();
    let localUrl = url;
    const headers: any = {
      "Content-Type": "application/json",
      ...(process.env.REACT_APP_CMS_API &&
      localUrl.includes(process.env.REACT_APP_CMS_API)
        ? { Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}` }
        : {}),
    };
    if (query.routeParams) {
      forEach(query.routeParams, (value, key) => {
        localUrl = localUrl.replace(`{${key}}`, value);
      });
    }
    if (localUrl.includes("content/items"))
      console.log("debuggy - querying url", localUrl);
    axios
      .get(
        `${localUrl}${query.filterString ? "?" : ""}${
          query.filterString ?? ""
        }`,
        { headers }
      )
      .then(
        (resp: AxiosResponse) =>
          actions.onSuccess({
            ...resp.data,
            addOnData: query.addOnData,
            addOnDataKey: query.addOnDataKey,
          }),
        (error: any) => actions.onError(error.response)
      );
  }),
  setData: action((state, payload: any) => {
    state.data = payload;
  }),
  clear: action((state) => {
    state.loading = false;
    state.success = false;
    state.data = {};
    state.errorData = null;
  }),
  post: thunk(async (actions, query: RequestValues<QueryModel>) => {
    actions.onRequest();
    axios
      .post(url, query.values, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (resp: AxiosResponse) => actions.onSuccess(resp.data),
        (error: any) => actions.onError(error.response)
      );
  }),
  authPostFetch: thunk(async (actions, query: RequestValues<QueryModel>) => {
    actions.onRequest();
    // @ts-ignore
    const { token, ...values } = query.values;
    let data: Omit<QueryModel, "token"> | { payload: string } | undefined =
      values;
    if (!query.dontEncryptAuthCall) {
      data = {
        payload: cryptoJs.AES.encrypt(
          JSON.stringify(values),
          process.env.REACT_APP_ENCRYPTION_SECRET as string
        ).toString(),
      };
    }
    axios
      .post(url, data, {
        headers: {
          "Content-Type": APPLICATION_JSON,
          Authorization: `Bearer ${get(query, "values.token", "")}`,
        },
      })
      .then(
        (resp: AxiosResponse) => {
          actions.onSuccess({ ...resp.data, addOnData: query.addOnData });
        },
        (error: any) => actions.onError(error.response)
      );
  }),
  authPutFetch: thunk(async (actions, query: RequestValues<QueryModel>) => {
    actions.onRequest();
    // @ts-ignore
    const { token, ...values } = query.values;
    let data: Omit<QueryModel, "token"> | { payload: string } | undefined =
      values;
    if (!query.dontEncryptAuthCall) {
      data = {
        payload: cryptoJs.AES.encrypt(
          JSON.stringify(values),
          process.env.REACT_APP_ENCRYPTION_SECRET as string
        ).toString(),
      };
    }
    let localUrl = url;
    if (query.routeParams) {
      forEach(query.routeParams, (value, key) => {
        localUrl = localUrl.replace(`{${key}}`, value);
      });
    }
    axios
      .put(localUrl, data, {
        headers: {
          "Content-Type": APPLICATION_JSON,
          Authorization: `Bearer ${get(query, "values.token", "")}`,
        },
      })
      .then(
        (resp: AxiosResponse) => {
          actions.onSuccess({ ...resp.data, addOnData: query.addOnData });
        },
        (error: any) => actions.onError(error.response)
      );
  }),
  authGetFetch: thunk(async (actions, query: RequestValues<QueryModel>) => {
    actions.onRequest();
    let localUrl = url;
    // @ts-ignore
    const { token, ...values } = query.values;
    let data: Omit<QueryModel, "token"> | { payload: string } | undefined =
      values;
    if (query.routeParams) {
      forEach(query.routeParams, (value, key) => {
        localUrl = localUrl.replace(`{${key}}`, value);
      });
    }
    if (!query.dontEncryptAuthCall) {
      data = {
        payload: cryptoJs.AES.encrypt(
          JSON.stringify(values),
          process.env.REACT_APP_ENCRYPTION_SECRET as string
        ).toString(),
      };
    }
    if (query.routeParams) {
      forEach(query.routeParams, (value, key) => {
        localUrl = localUrl.replace(`{${key}}`, value);
      });
    }
    axios
      .get(localUrl, {
        params: data,
        headers: {
          "Content-Type": APPLICATION_JSON,
          Authorization: `Bearer ${get(query, "values.token", "")}`,
        },
      })
      .then(
        (resp: AxiosResponse) => {
          actions.onSuccess({
            ...resp.data,
            addOnData: query.addOnData,
          });
        },
        (error: any) => actions.onError(error.response)
      );
  }),
  authDeleteFetch: thunk(async (actions, query: RequestValues<QueryModel>) => {
    actions.onRequest();
    // @ts-ignore
    let localUrl = url;
    if (query.routeParams) {
      forEach(query.routeParams, (value, key) => {
        localUrl = localUrl.replace(`{${key}}`, value);
      });
    }
    axios
      .delete(localUrl, {
        headers: {
          "Content-Type": APPLICATION_JSON,
          Authorization: `Bearer ${get(query, "values.token", "")}`,
        },
      })
      .then(
        (resp: AxiosResponse) => {
          actions.onSuccess({
            ...resp.data,
            addOnData: query.addOnData,
          });
        },
        (error: any) => actions.onError(error.response)
      );
  }),
});
