import React from "react";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import { ActionCreator } from "easy-peasy";
import { useUnmount, useUpdate, useUpdateEffect } from "react-use";
import { useNavigate, useLocation } from "react-router-dom";
import { useComponentWillMount } from "app/hooks/useCompWillMount";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { TRANSACTION_TYPES } from "app/pages/explorer/components/transaction-type/data";
import {
  defaultAppliedFilters,
  AppliedFiltersStateModel,
} from "app/state/api/action-reducers/explorer/appliedFilters";

const apiFilterFields = {
  startDate: "startDate",
  endDate: "endDate",
  country: "country",
  publisher: "publisher",
  sector: "sector",
  activityStatus: "activityStatus",
  transactionType: "transactionType",
};

export function useUrlFilters() {
  const navigate = useNavigate();
  const location = useLocation();

  const [latestNavQS, setLatestNavQS] = React.useState<string>("");

  const defaultYear =
    process.env.REACT_APP_DEFAULT_YEAR_FILTER ??
    (new Date().getFullYear() - 1).toString();
  const defaultStartDate = `${defaultYear}-01-01`;
  const defaultEndDate = `${defaultYear}-12-31`;

  const selectedFilters = useStoreState((state) => state.appliedFilters);
  const setSelectedFilters = useStoreActions(
    (actions) => actions.appliedFilters.setAllFilters
  );

  const inActivitiesPage = React.useMemo(
    () => location.pathname === "/explorer/activities",
    [location.pathname]
  );

  useComponentWillMount({
    action: () => {
      const updatedSelectedFilters = { ...selectedFilters };
      const currentURLParams = new URLSearchParams(location.search);
      const startDate = currentURLParams.get(apiFilterFields.startDate);
      const endDate = currentURLParams.get(apiFilterFields.endDate);
      const countries = currentURLParams.get(apiFilterFields.country);
      const sectors = currentURLParams.get(apiFilterFields.sector);
      const publishers = currentURLParams.get(apiFilterFields.publisher);
      const activityStatus = currentURLParams.get(
        apiFilterFields.activityStatus
      );
      const budgetMin = currentURLParams.get("budgetMin");
      const budgetMax = currentURLParams.get("budgetMax");
      const transactionType = currentURLParams.get(
        apiFilterFields.transactionType
      );

      if (startDate) {
        updatedSelectedFilters.startDate = startDate;
      }
      if (endDate) {
        updatedSelectedFilters.endDate = endDate;
      }
      if (countries) {
        updatedSelectedFilters.countries = countries.split(",");
      }
      if (sectors) {
        updatedSelectedFilters.sectors = sectors.split(",");
      }
      if (publishers) {
        updatedSelectedFilters.publishers = publishers.split(",");
      }
      if (activityStatus) {
        updatedSelectedFilters.activityStatus = activityStatus.split(",");
      }
      if (budgetMin) {
        updatedSelectedFilters.budgetMin = parseInt(budgetMin, 10);
      }
      if (budgetMax) {
        updatedSelectedFilters.budgetMax = parseInt(budgetMax, 10);
      }
      if (transactionType) {
        const fTType = find(TRANSACTION_TYPES, {
          code: parseInt(transactionType, 10),
        });
        if (fTType) {
          updatedSelectedFilters.transactionType = fTType;
        }
      }

      setSelectedFilters(updatedSelectedFilters);
    },
  });

  useUnmount(() => setSelectedFilters(defaultAppliedFilters));

  useUpdateEffect(() => {
    const currentUrlParams = new URLSearchParams(location.search);
    if (
      selectedFilters.startDate &&
      (selectedFilters.startDate !== defaultStartDate || inActivitiesPage)
    ) {
      currentUrlParams.set(
        apiFilterFields.startDate,
        selectedFilters.startDate
      );
    } else {
      currentUrlParams.delete(apiFilterFields.startDate);
    }
    if (
      selectedFilters.endDate &&
      (selectedFilters.endDate !== defaultEndDate || inActivitiesPage)
    ) {
      currentUrlParams.set(apiFilterFields.endDate, selectedFilters.endDate);
    } else {
      currentUrlParams.delete(apiFilterFields.endDate);
    }
    if (selectedFilters.countries.length > 0) {
      currentUrlParams.set(
        apiFilterFields.country,
        selectedFilters.countries.join(",")
      );
    } else {
      currentUrlParams.delete(apiFilterFields.country);
    }
    if (selectedFilters.sectors.length > 0) {
      currentUrlParams.set(
        apiFilterFields.sector,
        selectedFilters.sectors.join(",")
      );
    } else {
      currentUrlParams.delete(apiFilterFields.sector);
    }
    if (selectedFilters.publishers.length > 0) {
      currentUrlParams.set(
        apiFilterFields.publisher,
        selectedFilters.publishers.join(",")
      );
    } else {
      currentUrlParams.delete(apiFilterFields.publisher);
    }
    if (selectedFilters.activityStatus.length > 0) {
      currentUrlParams.set(
        apiFilterFields.activityStatus,
        selectedFilters.activityStatus.join(",")
      );
    } else {
      currentUrlParams.delete(apiFilterFields.activityStatus);
    }
    if (selectedFilters.budgetMin > 0) {
      currentUrlParams.set("budgetMin", selectedFilters.budgetMin.toString());
    }
    if (selectedFilters.budgetMax > 0) {
      currentUrlParams.set("budgetMax", selectedFilters.budgetMax.toString());
    }
    if (
      selectedFilters.transactionType &&
      selectedFilters.transactionType?.code !== TRANSACTION_TYPES[0].code &&
      !inActivitiesPage
    ) {
      currentUrlParams.set(
        apiFilterFields.transactionType,
        selectedFilters.transactionType.code.toString()
      );
    } else {
      currentUrlParams.delete(apiFilterFields.transactionType);
    }

    const queryString = decodeURIComponent(currentUrlParams.toString());

    if (latestNavQS !== queryString) {
      const changeIsOnlyInTransactionType =
        !latestNavQS.includes(apiFilterFields.transactionType) &&
        queryString.includes(apiFilterFields.transactionType);
      setLatestNavQS(queryString);
      navigate(
        {
          pathname: location.pathname,
          search: queryString,
        },
        { replace: changeIsOnlyInTransactionType }
      );
    }
  }, [selectedFilters]);

  useUpdateEffect(() => {
    onLocationSearchChange(selectedFilters, setSelectedFilters);
  }, [location.search]);

  useUpdateEffect(() => {
    setLatestNavQS("");
  }, [location.pathname]);

  return null;
}

export function onLocationSearchChange(
  selectedFilters: AppliedFiltersStateModel,
  setSelectedFilters: ActionCreator<AppliedFiltersStateModel>
) {
  const updatedSelectedFilters = { ...selectedFilters };
  const currentURLParams = new URLSearchParams(window.location.search);
  const startDate = currentURLParams.get(apiFilterFields.startDate);
  const endDate = currentURLParams.get(apiFilterFields.endDate);
  const countries = currentURLParams.get(apiFilterFields.country);
  const sectors = currentURLParams.get(apiFilterFields.sector);
  const publishers = currentURLParams.get(apiFilterFields.publisher);
  const activityStatus = currentURLParams.get(apiFilterFields.activityStatus);
  const budgetMin = currentURLParams.get("budgetMin");
  const budgetMax = currentURLParams.get("budgetMax");
  const transactionType = currentURLParams.get(apiFilterFields.transactionType);

  if (startDate) {
    updatedSelectedFilters.startDate = startDate;
  } else if (updatedSelectedFilters.startDate) {
    updatedSelectedFilters.startDate = "";
  }
  if (endDate) {
    updatedSelectedFilters.endDate = endDate;
  } else if (updatedSelectedFilters.endDate) {
    updatedSelectedFilters.endDate = "";
  }
  if (countries) {
    updatedSelectedFilters.countries = countries.split(",");
  } else if (updatedSelectedFilters.countries.length > 0) {
    updatedSelectedFilters.countries = [];
  }
  if (sectors) {
    updatedSelectedFilters.sectors = sectors.split(",");
  } else if (updatedSelectedFilters.sectors.length > 0) {
    updatedSelectedFilters.sectors = [];
  }
  if (publishers) {
    updatedSelectedFilters.publishers = publishers.split(",");
  } else if (updatedSelectedFilters.publishers.length > 0) {
    updatedSelectedFilters.publishers = [];
  }
  if (activityStatus) {
    updatedSelectedFilters.activityStatus = activityStatus.split(",");
  } else if (updatedSelectedFilters.activityStatus.length > 0) {
    updatedSelectedFilters.activityStatus = [];
  }
  if (budgetMin) {
    updatedSelectedFilters.budgetMin = parseInt(budgetMin, 10);
  }
  if (budgetMax) {
    updatedSelectedFilters.budgetMax = parseInt(budgetMax, 10);
  }
  if (transactionType) {
    const fTType = find(TRANSACTION_TYPES, {
      code: parseInt(transactionType, 10),
    });
    if (fTType) {
      updatedSelectedFilters.transactionType = fTType;
    } else {
      updatedSelectedFilters.transactionType = TRANSACTION_TYPES[0];
    }
  } else if (updatedSelectedFilters.transactionType) {
    updatedSelectedFilters.transactionType = TRANSACTION_TYPES[0];
  }

  if (!isEqual(selectedFilters, updatedSelectedFilters)) {
    setSelectedFilters(updatedSelectedFilters);
  }
}
