import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerOrganisationsDonut: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-donut/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerOrganisationsNetwork: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-network/{period}`
  ),
};

export const ExplorerOrganisationOfTheMonth: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/organisation-of-the-month`
  ),
};

export const ExplorerOrganisationsList: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/organisation/list`),
};

export const ExplorerOrganisationStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/organisation/stats/{code}/{transactionTypeCode}/{year}`
  ),
};

export const ExplorerOrganisationPartners: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/organisation/{code}/partners`
  ),
};

export const ExplorerHomeOrganisationsDonut: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-donut/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerHomeOrganisationsNetwork: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-network/{period}`
  ),
};

export const ExplorerProjectionsOrganisationsDonut: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-donut/{transactionTypeCode}/{period}`
  ),
};

// Organisation Page

export const ExplorerOrganisationPageOverviewStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/organisation/detail/{code}/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerOrganisationPageLocations: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/locations/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerOrganisationPageSDGs: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sdgs/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerOrganisationOrganisationsNetwork: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/organisation/{code}/partners`
  ),
};

export const ExplorerOrganisationSectors: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/sector-donut/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerOrganisationPageActivityTable: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/activity/list/{page}`),
};

export const ExplorerOrganisationPageDatasetTable: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/organisation/{code}/datasets`
  ),
};
