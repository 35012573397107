import React from "react";
import Grid from "@mui/material/Grid";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";
import { ReactComponent as UserGuideIcon } from "app/assets/vectors/HeaderResourcesViewUserGuide.svg";
import { ReactComponent as DataServicesIcon } from "app/assets/vectors/HeaderResourcesViewDataServices.svg";

const items = [
  {
    title: "User Guide",
    icon: UserGuideIcon,
    subtitle: "Read FAQ, search for tutorials and information on AIDA.",
    link: "/user-guide",
  },
  {
    title: "Data Services",
    icon: DataServicesIcon,
    subtitle: "Read on our Service Offers, AIDA product and tools.",
    link: "/data-services?v=publisher",
  },
];

export const HeaderResourcesView: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12} md={12} lg={4} xl={3} key={item.title}>
          <HeaderViewCommonBlockItem
            title={item.title}
            link={item.link}
            icon={<item.icon />}
            subtitle={item.subtitle}
          />
        </Grid>
      ))}
    </Grid>
  );
};
