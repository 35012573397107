import { ConnectedAccountsModel } from "../interfaces";
import { action } from "easy-peasy";

export const ConnectedAccounts: ConnectedAccountsModel = {
  googleDriveConnected: false,
  oneDriveConnected: false,
  setGoogleDriveConnected: action((state, payload) => {
    state.googleDriveConnected = payload;
  }),
  setOneDriveConnected: action((state, payload) => {
    state.oneDriveConnected = payload;
  }),
};
