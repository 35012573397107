import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const group: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/group`),
};

export const createGroup: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/group`),
};

export const updateGroup: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/group/{id}`),
};

export const groupMembers: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/group/{id}/members`),
};
