import React from "react";
import { colors } from "app/theme";
import Grid from "@mui/material/Grid";
import { HeaderViewCommonBlockItem } from "app/components/header/sub-components/expanded-view/data";
import { ReactComponent as YourDataHubIcon } from "app/assets/vectors/HeaderProductViewYourDataHub.svg";
import { ReactComponent as AIDAIATIPublisherIcon } from "app/assets/vectors/HeaderProductViewAIDAIATIPublisher.svg";

const items = [
  {
    title: "AIDA IATI Publisher",
    icon: AIDAIATIPublisherIcon,
    subtitle: "Publish your data to IATI registry with ease.",
    link: "/publisher",
  },
  {
    title: "Your Data Hub",
    icon: YourDataHubIcon,
    subtitle: "See your data visualised all in one place.",
    link: "/data-hub",
  },
];

export const HeaderPublisherView: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12} md={12} lg={4} xl={3} key={item.title}>
          <HeaderViewCommonBlockItem
            title={item.title}
            link={item.link}
            icon={<item.icon />}
            subtitle={item.subtitle}
            hoverTitleColor={colors.primary.green}
          />
        </Grid>
      ))}
    </Grid>
  );
};
