import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const ExplorerSectors: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sectors/{transactionTypeCode}/{year}`
  ),
};

export const ExplorerSectorOfTheMonth: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/sector-of-the-month`),
};

export const ExplorerSectorStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/sector/stats/{code}/{transactionTypeCode}/{year}`
  ),
};

export const ExplorerSectorPartners: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-network/{period}`
  ),
};

export const ExplorerSectorsList: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/sector/list/{transactionTypeCode}`
  ),
};

export const ExplorerHomeSectors: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sectors/{transactionTypeCode}/{year}`
  ),
};

export const ExplorerProjectionsSectors: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/sector-donut/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerSectorsOrganisationsDonut: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-donut/{transactionTypeCode}/{period}`
  ),
};

// Sector Page

export const ExplorerSectorPageOverviewStats: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/sector/detail/{code}/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerSectorPageLocations: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/locations/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerSectorPageSDGs: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sdgs/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerSectorOrganisationsNetwork: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-network/{period}`
  ),
};

export const ExplorerSectorOrganisationsDonut: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/organisations-donut/{transactionTypeCode}/{period}`
  ),
};

export const ExplorerSectorPageActivityTable: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/explorer/activity/list/{page}`),
};

export const ExplorerSectorPageSectorFunding: ApiCallModel = {
  ...APIModel(
    `${process.env.REACT_APP_API}/explorer/general/sectors/{transactionTypeCode}/{year}`
  ),
};
